/**
 * Created by kodix on 17.11.2016.
 */

(function ($) {
    $.fn.AjaxFileUpload = function (options) {

        var defaults = {
                action: "/ajax/feedback/feedback.php?addfile=true",
                onChange: function (filename) {
                },
                onSubmit: function (filename) {
                },
                onComplete: function (filename, response) {
                }
            },
            settings = $.extend({}, defaults, options),
            randomId = (function () {
                var id = 0;
                return function () {
                    return "_AjaxFileUpload" + id++;
                };
            })();

        return this.each(function () {
            var $this = $(this);
            if ($this.is("input") && $this.attr("type") === "file") {
                $this.bind("change", onChange);
            }
        });

        function onChange(e) {
            var $element = $(e.target),
                id = $element.attr('id'),
                $clone = $element.removeAttr('id').clone().attr('id', id).AjaxFileUpload(options),
                filename = $element.val().replace(/.*(\/|\\)/, ""),
                iframe = createIframe(),
                form = createForm(iframe);

            // We append a clone since the original input will be destroyed
            $clone.insertBefore($element);

            settings.onChange.call($clone[0], filename);

            iframe.bind("load", {element: $clone, form: form, filename: filename}, onComplete);

            form.append($element).bind("submit", {
                element: $clone,
                iframe: iframe,
                filename: filename
            }, onSubmit).submit();
        }

        function onSubmit(e) {
            var data = settings.onSubmit.call(e.data.element, e.data.filename);

            // If false cancel the submission
            if (data === false) {
                // Remove the temporary form and iframe
                $(e.target).remove();
                e.data.iframe.remove();
                return false;
            } else {
                // Else, append additional inputs
                for (var variable in data) {
                    $("<input />")
                        .attr("type", "hidden")
                        .attr("name", variable)
                        .val(data[variable])
                        .appendTo(e.target);
                }
            }
        }

        function onComplete(e) {
            var $iframe = $(e.target),
                doc = ($iframe[0].contentWindow || $iframe[0].contentDocument).document,
                response = doc.body.innerHTML;
            var jsonData = $.parseJSON(response);
            if (jsonData.error) {
                KDX.Dialog.alert(jsonData.text);
            }

            settings.onComplete.call(e.data.element, e.data.filename, response);

            // Remove the temporary form and iframe
            e.data.form.remove();
            $iframe.remove();
        }

        function createIframe() {
            var id = randomId();

            // The iframe must be appended as a string otherwise IE7 will pop up the response in a new window
            // http://stackoverflow.com/a/6222471/268669
            $("body")
                .append('<iframe src="javascript:false;" name="' + id + '" id="' + id + '" style="display: none;"></iframe>');

            return $('#' + id);
        }

        function createForm(iframe) {
            return $("<form />")
                .attr({
                    method: "post",
                    action: settings.action,
                    enctype: "multipart/form-data",
                    target: iframe[0].name
                })
                .hide()
                .appendTo("body");
        }
    };
})(jQuery);

$(function(){
    /*var hash = (window.location.hash.substr(1));
    if (hash.length > 1){
        setTimeout(function() {
            var anchor = $(document).find('a[name=' + hash + ']');
            if ($(anchor).offset()) {
                var newpos = $(anchor).offset().top - 200;
                console.log(newpos);
                $(document.body).animate({
                    'scrollTop': newpos
                }, 200, "swing");
            }
        }, 3000);
    }*/

    // Плавный скролл к якорям (для глоссария)
    $('a').click(function(){
        var href = $(this).attr('href');
        if (href && href.substr(0,1) == '#' && href.length > 1) {
            var anchor = $(this).attr('href');
            var newpos = $(anchor).offset().top - 100;
            if (newpos <=0) newpos = 0;
            $(document.body).animate({
                'scrollTop': newpos
            }, 200, "swing");
        }
    });
    $('.tester__submenu-item').click(function(e){
        if ($(this).attr('href') !== '#') return true;
        e.preventDefault();
        $('.tester__submenu-item').removeClass('tester__submenu-item--current');
        $(this).addClass('tester__submenu-item--current');
        $('.tester__primary-desc-img img').attr('src', $(this).find('.tester__submenu-img').attr('src'));
    });

    $('#feedback_popup_form [name="FILE"]').AjaxFileUpload({
        onComplete: function (filename, response) {
            $(this).closest('.upload').find('.add__image-text').text(filename);
            //$(this).closest('.upload').addClass('added');
            //$(this).closest('.upload').find('input').closest('.upload').find('.close_icon').css('display', 'inline-block');
            var $res = $.parseJSON(response);
            if ($res.IDFILE) {
                $('[name="IDFILE"]').val($res.IDFILE);
                $('[name="FILE_NAME"]').val(filename);
            }

            return false;
        }
    });

    $('#feedback_popup_form').submit(function(e){
        e.preventDefault();
        var formData = new FormData(this);
        KDX.ajax(
            "/ajax/feedback/feedback.php", {
                data: formData,
                type: 'POST',
                processData: false,
                contentType: false,
                success: function(result){
                    var data = JSON.parse(result);
                    if (data.SUCCESS === 'Y'){
                        $('[data-popup-id="feedback"] .popup__heading').html('Ваша заявка принята. Спасибо!');
                        $('[data-popup-id="feedback"] .popup__body').html('');
                    } else {
                        $('#feedback_popup_form .form-row__error').css('display', 'none');
                        data.ERRORS.forEach(function(error){
                            if (error.type === 'captcha') {
                                $('#feedback_popup_form input[name=captcha_code]').attr('value', error.new_code);
                                $('#feedback_popup_form .captcha_img').attr('src', error.new_picture);
                                $('#feedback_popup_form input[name=captcha_word]').siblings().filter('.form-row__error').html(error.text).css('display', 'block');
                            } else {
                                $('#feedback_popup_form input[name='+error.type+']').siblings().filter('.form-row__error').html(error.text).css('display', 'block');
                            }
                        });
                    }
                }
            });
    })
});

$(function(){
    $('[data-popup-id="feedback"] .add__image-label input[type="file"]').on('change',function(){
        var arr = $(this).val().split(/[\/\\]/);
        $(this).closest('.add__image-label').find('.add__image-text').html(arr[arr.length-1]);
    });

    $('a.js_image_popup').each(function(i, elem){
        $(this).addClass('js_popup').attr('data-popup', 'image-' + i).attr('href', '');
        $('.popup__wrapper').append(
            '<div class="popup" data-popup-id="image-' + i + '">'+
                '<div class="popup__close"></div>' +
                '<div class="popup_inner" style="padding:60px;">' +
                    '<img class="ilform__side-banner-img" src="' + $(elem).attr('src') + '" alt="" style="max-height: 600px;">' +
                '</div>' +
            '</div>'
        );
    });
	
	$('.policy__close').click(function(){
		$('.policy').css({'display' : 'none'});
	});
});


